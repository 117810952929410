<div class="transbox homepage-container">
    <span>
        <p>Welcom to registration for </p>
        <p>THE NEWTON Sixth Form School</p>
        <h1 class="homepage-h1">CHARACTER MATTERS</h1>
        <p>The school that aims to build future leaders</p>
        <!-- <p>
            <a class="homepage-btn-enroll" routerLink="/enrollment">Start to be newtonian</a>
        </p> -->
    </span>
</div>