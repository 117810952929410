<header class="header" id="header">
    <div class="header_toggle" (click)="toggleClick()">
        <img src="/assets/images/newton-logo.png" alt="">
        
    </div>
    <div>
        <p class="mb-0" style="font-size: 15px;color: #ffffff;text-align: center;margin: 0 1rem;">THE NEWTON <br>Sixth Form School</p>
    </div>
    
    <!-- <div class="header_img">
        <img src="https://i.imgur.com/hczKIze.jpg" alt="">
    </div> -->
</header>

<div class="height-100">
    <router-outlet></router-outlet>
</div>